
const C = {
  apiOrigin: 'https://email.wasya.co',
  // apiOrigin: 'http://email.local:3004',

  keycloak_url: 'https://auth.wasya.co',
  keycloak_realm: 'wco_reporting',
  keycloak_client_id: 'wco',
}

export default C
